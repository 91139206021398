<template>
  <div class="container">
    <el-button class="pdf" @click="printCert()" v-show="show">Generate Report</el-button>
    <!--startprint-->
    <div id="report_display" class="mainStyle">
      <div class="topInfoStyle">

        <div class="projectStyle">{{studentInfo.project_ename}}</div>
        <div class="projectNameStyle">{{studentInfo.project_name_en}}</div>
        <div style="clear: both;"></div>
        <div class="projectTitleStyle" style="page-break-before: avoid;">{{studentInfo.project_title}}</div>

        <div class="projectStudentStyle" style="page-break-before: avoid;">{{studentInfo.student_names}}</div>
        <div class="projectAccountStyle" >{{studentInfo.account_nameEN}}</div>

        
        <!-- <div v-for="item in studentInfo.students_arr" :key="item" class="name_item">{{item.student_name}},&nbsp;{{item.account_name}}</div> -->

      </div>
     
      <div class="block" v-for="(item) in allContent" :key="item._id.$id">

        
        <div v-if="item.template_block !== 'Project Title'" :class="(item.template_block === 'Presentation Recording' || item.template_block === 'Grant of License' || item.template_block === 'Learning Materials') ? 'other-ele':'ele'">
          <div v-if="item.block_level === '1'" class="head1"> 
            {{item.template_block}} 
          
           

            <!-- <p style="color:gray" v-if="item.block_content.length === 0 && item.block_attaches.length === 0 && item.block_level === '2'"> <i>This part is absent or not available temporarily.</i></p> -->

            <div class="contentStyle" v-if="item.block_content != 0">
              <div v-for="content in item.block_content" :key="content" class="content"> 
              
                <div v-html="content"></div>
              </div>
            </div>


            <div class="file" v-for="attach in item.block_attaches" :key="attach"> 
              <i class="el-icon-document"></i><a :href="attach.attach_address" target="_blank"> {{attach.attach_name}} </a>
            </div>
          
          </div>

          <div v-if="item.block_level === '2'" class="head2"> 
              {{item.template_block}} 
            
              <p style="color:gray;font-size: 12px;" v-if="item.block_content.length === 0 && item.block_attaches.length === 0 && item.block_level === '2'"> <i>This part is absent or not available temporarily.</i></p>

              <div class="contentStyle"  v-if="item.block_content != 0">
                <div v-for="content in item.block_content" :key="content" class="content"> 
                
                  <div v-html="content"></div>
                </div>

              </div>


              <div class="file" v-for="attach in item.block_attaches" :key="attach"> 
                <i class="el-icon-document"></i><a :href="attach.attach_address" target="_blank"> {{attach.attach_name}} </a>
              </div>

          </div>

          <div style="height: 10px;"></div>



          
          

        </div>
        
        
      </div>
      <div class="judgeComments">
          <h2 class="head1">Judge Comments</h2>
          <div v-for="content in judgeComments" :key="content._id.$id" class="content"> 
            <!-- <h4>{{content.block_name}}</h4> -->
            <p><q> {{content.comment_text}} </q></p>
          </div>

      </div>
    </div>
    <!--endprint-->
  </div>

</template>

<script>
import Cookie from 'js-cookie'
import {  
    netService,
} from '../../api/pgservice.js'
export default {
  name: 'Toprint',
  props: {
    
  },
  computed:{
    name(){
      return Cookie.get('user_name')
    }
  },
  data() {
    return{
      allContent: [],
      show: false,
      studentInfo: [],
      judgeComments: [],
      block_id:'',
      template_id:''
    }
  },
  mounted() {
   
    this.project_id = this.$router.currentRoute.query.project_id
    this.team_code = this.$router.currentRoute.query.team_code
    this.account_id = this.$router.currentRoute.query.account_id
    this.block_id = this.$router.currentRoute.query.block_id
    this.template_id = this.$router.currentRoute.query.template_id

    
    this.getPdf()
    this.getProfile()
    this.getJudgeComments()
  },
  methods: {
    getPdf(){
        netService({
          functionName: 'getStudentAllBlocks',
  
          project_id: this.project_id,
          account_id :this.account_id,
          team_code: this.team_code,
          block_id:this.block_id,
          template_id:this.template_id
          }).then(data=>{
            this.show = true
            this.allContent = data 
            this.allContent.map( (item) => {
              if (item.block_attaches.length !== 0) {
                item.block_attaches.map((address) => {
                  if(address.attach_address.substr(0,7).toLowerCase() == "http://" || address.attach_address.substr(0,8).toLowerCase() == "https://") {
                    address.attach_address = address.attach_address
                  } else {
                    address.attach_address = "http://" + address.attach_address
                  }
                })
              }
            })
            console.log(this.allContent)
          })
      },
    getProfile(){
      console.log('project_id:'+this.project_id)
      console.log('account_id:'+this.account_id)
      console.log('team_code:'+this.team_code)
      netService({
        functionName: 'getStudentProfile',
        project_id: this.project_id,
        account_id :this.account_id ,
        team_code: this.team_code ,
        block_id:this.block_id,
        template_id:this.template_id

      }).then(data=>{
        console.log(data)
        this.studentInfo = data 
            
      })
    },
    p(e){
      console.log(e.path)

      e.path[1].style.display = 'none'
      document.title = this.studentInfo.project_name_en + '-' +this.studentInfo.account_nameEN+'-Team '+this.team_code
      window.print()
      e.path[1].style.display = 'block'
      
      

    },

    printCert(){
        let subOutputRankPrint = document.getElementById('report_display');
        console.log(subOutputRankPrint.innerHTML);
        let newContent =subOutputRankPrint.innerHTML;
        let oldContent = document.body.innerHTML;
        document.body.innerHTML = newContent;
        window.print();
        window.location.reload();
        document.body.innerHTML = oldContent;
//            let bdhtml = window.document.body.innerHTML; //获取当前页的html代码
//            let sprnstr = "<!--startprint-->"; //设置打印开始区域
//            let eprnstr = "<!--endprint-->"; //设置打印结束区域
//            let prnhtml = bdhtml.substring(bdhtml.indexOf(sprnstr) + 18); //从开始代码向后取html
//            let prnhtmls = prnhtml.substring(0, prnhtml.indexOf(eprnstr)); //从结束代码向前取html
//            window.document.body.innerHTML = prnhtmls;
//            //window.document.body.innerHTML = bdhtml;
//            window.print();
//            window.document.body.innerHTML = bdhtml;
//            window.location.reload();
    },

    getJudgeComments(){
      netService({
        functionName: 'getJudgeComments',
         
        project_id: this.project_id,
        account_id :this.account_id ,
        team_code: this.team_code,
        template_block_id:this.block_id  ,
        template_id:this.template_id

      }).then(data=>{
        console.log(data)
        this.judgeComments = data
       
            
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container{
    padding: 10%;
    width:80%;
    margin:0 auto 0 auto;
    background-color: oldlace;
    position: relative;

  }
  .judgeComments{
    background-color: white;
    border-radius: 5px;
  }
  .topInfoStyle{
    background-color: white;

    border-radius: 5px;
    /* padding: 20px; */
    position: relative;
    margin-bottom: 10px;
  }
  .projectStyle{
    position: absolute;
    left: 20px;
    text-align: center;
    color: white;
    top: 20px;
    font-size: 20px;
    background-color: #ffb414;
  }
  .projectNameStyle{
    position: absolute;
    right: 20px;
    text-align: center;
    color: white;
    top: 20px;
    font-size: 20px;
    background-color: #ffb414;
  }
  .projectTitleStyle{
      /* margin-top: 40px;
      top: 40px; */
      padding-top: 70px;
      margin-left: 20px;
      margin-right: 20px;
      color:#204386;
      font-size: 25px;
      font-weight: 500;
  }
  .projectStudentStyle{
    margin-top: 20px;
    text-align: right;
    margin-right: 20px;

  }
  .projectAccountStyle{
    margin-top: 10px;
    text-align: right;
    margin-right: 20px;
    padding-bottom: 20px;
    color: #ffb414;
  }
  h2 {
    page-break-before: always;
  }
  .pdf{
    position: fixed;
    top: 3%;

    right: 1%;
    background-color:#1863bb;
    color:white;

  }

  .head1{

    /* padding-bottom: 10px; */
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    /* border-bottom:2px solid black; */
    color: #204386;
    /* margin-bottom:10px; */
    margin-top: 10px;
    font-size: 28px;
    border-radius: 5px;
    font-weight: 500;
    /* background-color: rebeccapurple; */
  }

 .head2{

    /* padding-top: 0px; */
    /* padding-left: 20px;
    padding-right: 40px; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 20px;

    margin-left: 20px;
    margin-right: 40px;
    /* padding-left: 10px; */
    /* border-left:2px solid black; */
    background-color: #f3f5f9;
    font-size: 18px;
    color: #204386;
    border-radius: 5px;

    /* background-color: red; */
    /* background-color: red; */

  }

  .name_item{

    margin:15px 5px;
  }

  p {
    line-height: 150%;
    word-break:normal;
    white-space:pre-warp;
    word-wrap:break-word;
  }
  .block{
    /* margin-top: 10px; */
    width: 100%;
    padding: 0px;
    background-color: white;
    border-radius: 5px;
    /* background-color: red; */
  }
  .contentStyle{
    background-color: #f3f5f6;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;

    /* background-color: rebeccapurple; */
  }
  .content {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    border-radius: 5px;
    white-space: pre-line;
    /* background-color: white; */
    color: #333;
    /* color: #a4a3a4; */
    font-size: 15px;
    /* color: red; */
    font-weight: 400;

  }
  .file {
    font-size: 15px;
    margin: 5px;
  }
  .file a:link {
    color: #aaa;
  }
  .file a:visited {
    font-size: 15px;
    color: #204386;
  }
  @media print {
    .other-ele {
      display: none;
    }
    h1 {font-size: 50px}
    h2 {font-size: 40px}
    h3 {font-size: 30px}
    p {font-size: 16px;}
  }
</style>
